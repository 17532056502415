<template>
	<div class="scene-properties">
		<button @click="saveCameraView()" class="editor-secondary-button" id="save-camera-view-btn">
			<span>Save camera view</span>
		</button>

		<div ref="propertiesContainer">

		</div>
		<!--
		<div id="editor-background-color-picker" class='editor-property-header'>Background color</div>
		<EditorColorPicker ref="backgroundColorPicker" id="background-color-picker" />

		<div class='editor-property-header'>Background gradient</div>
		<EditorSelector ref="selectorBackgroundGradient" class="editor-selector"></EditorSelector>

		<div class='editor-property-header'>Shadow</div>
		<EditorSelector ref="selectorShadowType" class="editor-selector"></EditorSelector>

		<div class='editor-property-header'>Gradient intensity</div>
		<EditorPropertySlider ref="vignetteIntensity" class="editor-selector"></EditorPropertySlider>

		<div class='editor-property-header'>User interface color</div>
		<EditorColorPicker ref="uiColorPicker" />
        -->
    </div>
</template>

<script>

import { nextTick } from 'vue';


export default {
	data: function() {
		return {
			sceneBackgroundColor: '#FFFFFF',
			backgroundGradient: "radial",
			shadowType: "directional",
			initIsDone: false,
			vignetteIntensity: 0,
			tintColorUI: "#FFFFFF",
			propertyEditConfig: [ 
				{ key: "showAnnotationsByDefault", type: "bool", title: "Show Annotations By Default" },
				{ key: "backgroundColor", type: "color", title: "Background color"},
				{ key: "backgroundGradient", type: "typeBased", title: "Background gradient", typeTitle: "Type", types: [
					{ value: "off", title: "Off", options: [

						]},
					{ value: "radial", title: "Gradient", options: [
						{ key: "vignetteIntensity", type: "limitedNumber", title: "Intensity", min: 0, max: 1 }
						]}
					],
				},
				{ key: "shadowType", type: "typeBased", title: "Shadow", typeTitle: "Type", types: [
					{ value: "off", title: "Off", options: [
	
						]},
					{ value: "directional", title: "Directional", options: [
						{ key: "sunZenithAngle", type: "limitedNumber", title: "Sun Zenith angle", min: 45, max: 90 },
						{ key: "sunAzimuthAngle", type: "limitedNumber", title: "Sun Azimuth Angle", min: 0, max: 360 },
						]},
					{ value: "blur", title: "Blur", options: [
						{ key: "blurShadowIntensity", type: "limitedNumber", title: "Intensity", min: 0, max: 2 },
						{ key: "blurShadowOffset", type: "limitedNumber", title: "Diffusion", min: 0.2, max: 1 },
						{ key: "selfShadow", type: "bool", title: "Self shadow" }
						]}
					]
				},
				{ key: "ui", type: "typeBased", title: "UI options", typeTitle: "Type", types: 
					[
						{ value: "default", title: "Off", options: 
							[
								{ key: "tintColorUI", type: "color", title: "User interface color"},
								{ key: "configuratorBackgroundColor", type: "color", enableAlpha: true, title: "Configurator button container color"},
								{ key: "configuratorTextColor", type: "color", title: "Configurator text color"}
							]
						}
					]
				},
				{ key: "autoRotation", type: "typeBased", title: "Auto-Rotation", typeTitle: "", types: 
					[
						{ value: "off", title: "Off", options: 
							[

							]
						},
						{ value: "on", title: "On", options: 
							[
								{ key: "autoRotationSpeed", type: "limitedNumber", title: "Speed", min: 0, max: 1 },
								{ key: "autoRotationDirection", type: "picker", title: "Direction", getOptions: function() {
									return [
											{value: "cw", title: "Clockwise"},
											{value: "ccw", title: "Anti-Clockwise"}
									]
								} },
							]
						}
					]
				}
			],
			jsonProps: null,
			notifyScenePropChange: true
		}
	},
	components: {

	}, 
	created() {

	},
	mounted() {
		if (process.env.NODE_ENV === 'development') {
			this.propertyEditConfig.push(
				{ key: "toneCorrection", type: "typeBased", title: "Color correction", typeTitle: "Type", types: [
					{ value: "default", title: "", options: [
							{key: "ToneCorrA", type: "limitedNumber", title: "Tone A", min: 0, max: 1},
							{key: "ToneCorrB", type: "limitedNumber", title: "Tone B", min: 0, max: 1},
							{key: "ToneCorrC", type: "limitedNumber", title: "Tone C", min: 0, max: 1},
							{key: "ToneCorrD", type: "limitedNumber", title: "Tone D", min: 0, max: 1},
							{key: "ToneCorrE", type: "limitedNumber", title: "Tone E", min: 0, max: 1},
							{key: "ToneCorrF", type: "limitedNumber", title: "Tone F", min: 0, max: 1}
						]}
					]
				}
			)
		}
	},
	methods: {
		saveCameraView() {
			this.$editor.module.ccall('callFromJS', null, ['string', 'string'], ["saveCameraView", null]);
			this.$editor.dismissSuccessMessageCountDown = 5;
			this.$editor.successMessage = "Camera view saved";

			this.$editor.onChangeIsMadeForce(true, false);
		},
		setProps(jsonProps) {
			this.jsonProps = jsonProps;

			let _this = this;

			nextTick(() => {
				_this.$refs.propertiesContainer.innerHTML = ""
				_this.$editor.addProperties(_this.propertyEditConfig, jsonProps, _this.$refs.propertiesContainer,  null, _this.onChangeProperty, _this.getPropertyType, _this.getPropertyValue, _this.onChangePropertyType)
				
				nextTick(() => {
					_this.initIsDone = true;
				})
			})
		},
		onChangeProperty(value, key) {
			if (!this.initIsDone) return;
			
			if (this.$editor.module != null) {
				var props = {}
				props["key"] = key
				if (Object.hasOwn(value, "value")) {
					value = value.value
				}
				if (typeof value === 'string' || value instanceof String) {
					props["value"] = value
				} else {
					props["value"] = value.toString()
				}
				this.$editor.module.ccall('callFromJS', null, ['string', 'string'], ["setSceneProperty", JSON.stringify(props, null, 4)]);
			}
			this.$editor.onChangeIsMadeForce(true, false)
		},
		// Get current prop type based on current material state
		getPropertyType(key) {
			if (key == "ui" || key == "toneCorrection") {
				return "default"
			}
			return this.jsonProps[key]
		},
		onChangePropertyType(key, type) {
			var props = {}
			props["key"] = key
			props["value"] = type
			this.$editor.module.ccall('callFromJS', null, ['string', 'string'], ["setSceneProperty", JSON.stringify(props, null, 4)]);
			this.$editor.onChangeIsMadeForce(true, false)
		},
	},
	watch: {
	}
}

</script>

<style lang="scss">
.scene-properties {
	padding: 0px 10px 0px 10px;
	overflow: auto;
	height: 100%;
}
#save-camera-view-btn {
	margin-top: 15px !important;
	margin-bottom: 5px !important;
	width: 100%;
}

.editor-selector {
	width: 100%;
	margin-bottom: 5px;
}

#editor-background-color-picker {
	margin-top: 5px;
}

</style>
