<template>
  <div class="scene-menu-panel">
    <div class="scene-menu-stat-container">
      <div class="scene-menu-downloads-button-container" id="scene-menu-downloads-button-container" title="Views"
        v-show="downloadsCount && downloadsCount > 0">
        <div class="scene-menu-downloads-button">{{ downloadsCount }}</div>
      </div>

      <div class="scene-menu-size-button-container" id="scene-menu-size-button-container" title="Model size">
        <div class="scene-menu-stat-separator">|</div>
        <div class="scene-menu-size-button">{{ modelSize }}</div>
      </div>

      <div id="scene-menu-triangles-button-container" title="Triangles count" v-show="trianglesCount > 0">
        <div class="scene-menu-stat-separator">|</div>
        <div class="scene-menu-triangles-button">{{ trianglesCountFormatted }}</div>
      </div>

      <div v-if="showSpaceBetweenGroups" class="scene-menu-space">
      </div>

      <div v-show="showShare && enableShare" class="scene-menu-share-button-container" @click="onShare">
        <div class="scene-menu-stat-separator">|</div>
        <div class="scene-menu-share-button">Share</div>
      </div>

      <div v-show="enableEmbed && scene" class="scene-menu-embed-button-container" @click="onEmbedClick">
        <div class="scene-menu-stat-separator">|</div>
        <div class="scene-menu-embed-button">Embed</div>
      </div>

      <div v-if="enableMap && longitude &&
          longitude !== 0 &&
          latitude &&
          latitude !== 0
          " class="scene-menu-map-button-container" @click="onShowMapClick">
        <div class="scene-menu-stat-separator">|</div>
        <div class="scene-menu-map-button">Map</div>
      </div>

      <div v-if="showSpaceBetweenGroups" class="scene-menu-space">
      </div>

      <div v-show="enableConfigurator && scene" class="scene-menu-configurator-button-container"
        @click="onConfiguratorClick">
        <div class="scene-menu-stat-separator">|</div>
        <div class="scene-menu-configurator-button">3D Configurator (Beta)</div>
      </div>

      <div v-show="enableSharePNG && scene" class="scene-menu-embed-button-container" @click="onSharePNGClick">
        <div class="scene-menu-stat-separator">|</div>
        <div class="">PNG</div>
      </div>

      <div v-show="enableSharePNG && scene" class="scene-menu-embed-button-container" @click="onShareJPGClick">
        <div class="scene-menu-stat-separator">|</div>
        <div class="">JPEG</div>
      </div>
    </div>

    <b-tooltip boundary="window" triggers="hover" target="scene-menu-downloads-button-container" placement="top">
      Views
    </b-tooltip>

    <b-tooltip boundary="window" triggers="hover" target="scene-menu-size-button-container" placement="top">
      Model size
    </b-tooltip>

    <b-tooltip boundary="window" triggers="hover" target="scene-menu-triangles-button-container" placement="top">
      Triangles count
    </b-tooltip>
  </div>
</template>

<script>

import { MODEL_FLAG } from "../const"

export default {
  props: {

  },
  data: function () {
    return {
      showShare: false,
      downloadsCount: 0,
      modelSize: 0,
      trianglesCount: 0,
      trianglesCountFormatted: 0,
      latitude: 0,
      longitude: 0,
      enableShare: true,
      enableMap: true,
      enableConfigurator: false,
      enableEmbed: false,
      showSpaceBetweenGroups: false,
      onShare: function () { },
      onShowMapClick: function () { },
      onConfigurator: function () { },
      scene: null,
      enableSharePNG: false,
      onSharePNGClick: function() {},
      onShareJPGClick: function() {}
    }
  },
  components: {

  },
  created() {

  },
  updated() {

  },
  mounted() {

  },
  methods: {
    configureModel(model) {
      if (!model) return

      this.showShare = model.flags.includes(MODEL_FLAG.IS_AVAILABLE_BY_QR_AND_LINK)

      let modelSize = 0
      let trianglesCount = 0

      if (model.arModels != null && model.arModels.length > 0) {
        let arModel = model.arModels[0]

        if (arModel.trianglesCount && arModel.trianglesCount > 0) {
          trianglesCount = arModel.trianglesCount
        }

        modelSize = arModel.size
      }
      this.configure(model.downloadsCount, modelSize, trianglesCount, model.latitude, model.longitude)

      this.scene = model
    },
    configure(downloadsCount, modelSize, trianglesCount, latitude, longitude) {
      if (downloadsCount) this.downloadsCount = downloadsCount
      if (trianglesCount) {
        this.trianglesCount = trianglesCount
        this.trianglesCountFormatted = this.formatNumber(trianglesCount)
      }
      if (modelSize) this.modelSize = this.humanFileSize(modelSize, true)
      if (latitude) this.latitude = latitude
      if (longitude) this.longitude = longitude
    },
    onConfiguratorClick() {
      let link = process.env.VUE_APP_WEBSITE_URL + "/archweb_conf.html?"
      link += "version=" + process.env.VUE_APP_ARCHWEB_VERSION
      link += "&scene=" + this.scene.hash
      link += "&preset=web-conf"
      if (process.env.NODE_ENV === "development") {
        link += "&allowCDN=0"
        link += "&resourcesURL=" + process.env.VUE_APP_STATIC_URL
        link += "&api=" + process.env.VUE_APP_API_BASEURL
        link += "&website-url=" + process.env.VUE_APP_WEBSITE_URL
      }

      window.open(link, "_blank")
    },
    onEmbedClick() {
      this.$root.$emit("showEmbedSceneWindow", this.scene.hash, "");
    }
  },
  watch: {

  }
}
</script>

<style lang="scss">
.scene-menu-panel {
  border-bottom: 1px solid #d8d8d8;
  position: relative;
}

.scene-menu-stat-container {
  line-height: 40px;
  display: flex;
  flex-wrap: wrap;
}

.scene-menu-stat-separator {
  color: #d8d8d8;
  margin-right: 8px;
  margin-left: 8px;
}

.scene-menu-button-container-common {
  display: flex;
  right: 5px;
  top: 8px;
  cursor: pointer;
}

.scene-menu-button-icon-common {
  content: "";
  position: absolute;
  display: block;
  background-repeat: no-repeat;
}

.scene-menu-button-common {
  color: black !important;
  font-weight: 400;
  position: relative;
  height: 100%;
  padding-left: 23px;
}

.scene-menu-space {
  width: 50px;
}

/* Map */
.scene-menu-map-button-container {
  @extend .scene-menu-button-container-common;
}

.scene-menu-map-button::before {
  @extend .scene-menu-button-icon-common;

  left: 0px;
  top: 12px;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
  background-image: url("./../assets/icon-map.svg");
}

.scene-menu-map-button:hover {
  text-decoration: underline;
}

.scene-menu-map-button {
  @extend .scene-menu-button-common;
}

/* Share */
.scene-menu-share-button-container {
  @extend .scene-menu-button-container-common;
}

.scene-menu-share-button {
  @extend .scene-menu-button-common;
}

.scene-menu-share-button:hover {
  text-decoration: underline;
}

.scene-menu-share-button::before {
  @extend .scene-menu-button-icon-common;

  left: 0px;
  top: 11px;
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  background-image: url("./../assets/share_black.svg");
}

/* Configurator */
.scene-menu-configurator-button-container {
  @extend .scene-menu-button-container-common;
}

.scene-menu-configurator-button::before {
  @extend .scene-menu-button-icon-common;

  left: 0px;
  top: 11px;
  width: 19px;
  height: 19px;
  background-size: 19px 19px;
  background-image: url("./../assets/scene_menu_configurator_black.svg");
}

.scene-menu-configurator-button {
  @extend .scene-menu-button-common;
}

.scene-menu-configurator-button:hover {
  text-decoration: underline;
}

/* Downloads */
.scene-menu-downloads-button-container {
  @extend .scene-menu-button-container-common;
}

.scene-menu-downloads-button {
  @extend .scene-menu-button-common;
}

.scene-menu-downloads-button::before {
  @extend .scene-menu-button-icon-common;

  left: 0px;
  top: 10px;
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background-image: url("./../assets/scene_menu_eye.svg");
}

/* Embed */
.scene-menu-embed-button-container {
  @extend .scene-menu-button-container-common;
}

.scene-menu-embed-button {
  @extend .scene-menu-button-common;
}

.scene-menu-embed-button:hover {
  text-decoration: underline;
}

.scene-menu-embed-button::before {
  @extend .scene-menu-button-icon-common;

  left: 0px;
  top: 11px;
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  background-image: url("./../assets/source_code_black.svg");
}

/* Triangles */
#scene-menu-triangles-button-container {
  @extend .scene-menu-button-container-common;
}

.scene-menu-triangles-button {
  @extend .scene-menu-button-common;
}

.scene-menu-triangles-button::before {
  @extend .scene-menu-button-icon-common;

  left: 0px;
  top: 11px;
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  background-image: url("./../assets/scene_menu_triangle.svg");
}
/* Size */
#scene-menu-size-button-container {
  @extend .scene-menu-button-container-common;
}

.scene-menu-size-button {
  color: black !important;
  font-weight: 400;
  position: relative;
  height: 100%;
}

</style>