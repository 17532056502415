<template>
	<div class="editor-property-container" v-b-tooltip.hover.html.left.window="tooltip">
		<b-form-checkbox v-model="value">{{titleText}}</b-form-checkbox>
    </div>
</template>

<script>


export default {
	props: {
		titleText: { type: String, default: "" },
		propKey: { type: String, default: "" },
		onChange: { type: Function }
	},
	data: function() {
		return {
			value: false,
			tooltip: ""
		}
	},
	components: {

	},
	created() {

	},
	mounted() {
		
	},
	methods: {
	
	},
	watch: {
		value (val, oldVal) {
			if (val === oldVal) return;

			this.onChange(val, this.propKey);
		}
	}
}

</script>

<style lang="scss">


</style>
