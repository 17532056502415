<template>
	<div>
		<div v-show="headerTitle.length > 0" ref="header" class="editor-property-header">{{headerTitle}}</div>
		<div ref="outliner" class="object-outliner">
			<div class="object-outliner-background" @click="deselect()"></div>
		</div>
	</div>
</template>

<script>

import ObjectItem from './ObjectItem.vue'


import Vue from 'vue'

export default {
	data: function() {
		return {
			objectItemList: [],
			rootObject: [],
			selectedItem: null,
			selectedObject: null,
			selectedNodeId: -1,
			selectedObjectIds: [],
			selectedIndex: -1,
			highlightedObject: null,
			highlightedNodeId: -1,
			onSelect: null,
			multiselect: false,
			scene: null,
			headerTitle: "",
			onSelectMultiple: null
		}
	},
	components: {

	},
	created() {

	},
	mounted() {

	},
	methods: {
		deleteItem(item) {
			for (let index in item.childrenItems) {
				let childItem = item.childrenItems[index]
				this.deleteItem(childItem)
			}
			let itemIndex = this.objectItemList.indexOf(item)
			this.objectItemList.splice(itemIndex, 1)

			if (item.$el.parentNode) {
				item.$el.parentNode.removeChild(item.$el);
			}
		},
		deleteObjects(deletionEntries) {
			for (let entryIndex in deletionEntries) {
				let deletionEntry = deletionEntries[entryIndex]

				// remove element
				for (let itemIndex in this.objectItemList) {
					let item = this.objectItemList[itemIndex]

					let hideItem = false;
					if (deletionEntry.meshId >= 0) {
						if(item.type == "mesh" && item.meshId == deletionEntry.meshId && item.parentObject && item.parentObject.type == "node" && item.parentObject.id == deletionEntry.nodeId) {
							hideItem = true;
						}
					} else {
						if(item.type == "node" && item.object.id == deletionEntry.nodeId) {
							hideItem = true
						}
					}

					if (hideItem) {
						this.deleteItem(item)
						//item.$el.hidden = true
							
						if (this.selectedItem == item) {
							this.selectedItem = null
							this.selectedObject = null
						}
					}
				}

				// remove objects
				if (deletionEntry.meshId >= 0) {
					// remove mesh from node
					let nodeObject = this.findNode(deletionEntry.nodeId)
					if (nodeObject) {
						let meshIndex = nodeObject.meshes.indexOf(deletionEntry.meshId)
						nodeObject.meshes.splice(meshIndex, 1)
					}
				} else {
					// remove child from node
					let nodeObject = this.findNode(deletionEntry.nodeId)
					if (nodeObject && nodeObject.parentId >= 0) {
						let parentObject = this.findNode(nodeObject.parentId)
						let nodeIndex = parentObject.children.indexOf(nodeObject)
						parentObject.children.splice(nodeIndex, 1)
					}
				}
			}
		},
		deleteCurrentSelection() {
			if (this.selectedItem) {
				this.deleteItem(this.selectedItem)
				this.selectedItem = null
				this.selectedObject = null
				this.selectedObjectIds = []
			}
		},
		updateSelections() {
			for (var i in this.objectItemList) {
				var item = this.objectItemList[i]
				if (item.object.type == "node") {
					item.subselected = this.isNodeSubSelected(item.object)
					item.subhighlighted = this.isNodeSubHighlighted(item.object)
				}
			}
		},
		configure(selectedObjectIds) {
			var scene = this.$editor.module.ccall('callFromJS', 'string', ['string'], ["getSceneDump"]);
			scene = JSON.parse(scene).scene.objects

			this.scene = scene;
			if (selectedObjectIds && selectedObjectIds instanceof Array) {
				this.selectedObjectIds = selectedObjectIds;
			}
			

			// remove items
			var itemList = this.objectItemList
			for (var itemIndex in itemList) {
				var el = itemList[itemIndex]
				if (el == null) continue;
				el.$el.parentNode.removeChild(el.$el);
			}
			
			this.objectItemList = [];

			if (scene) {
				var nodeArray = scene.rootNodeArray
				var prevElement = null
				for (var i in nodeArray) {
					var node = nodeArray[i]
					var newNodeItem = this.createItemForObject(node, 0, "node")
					this.insertAfter(newNodeItem.$el, prevElement, this.$refs.outliner)
					this.objectItemList.push(newNodeItem)
					prevElement = newNodeItem.$el
			}
			}
			
			this.updateSelections()
		},
		isMeshSelected(meshId, nodeId) {
			if (!this.selectedObjectIds) return false

			for (var i in this.selectedObjectIds) {
				var objectIdInfo = this.selectedObjectIds[i]
				if (objectIdInfo.meshId == meshId && objectIdInfo.nodeId == nodeId) {
					return true;
				}
			}

			if (this.selectedObject && this.selectedObject.type == "mesh" && this.selectedObject.id == meshId && this.selectedNodeId && nodeId == this.selectedNodeId) {
				return true;
			}
			return false
		},
		isMeshHighlighted(meshId, nodeId) {
			if (this.highlightedObject && this.highlightedObject.type == "mesh" && this.highlightedObject.id == meshId && this.highlightedNodeId && nodeId == this.highlightedNodeId) {
				return true;
			}
			return false
		},
		isNodeSelected(nodeId) {
			if (!this.selectedObjectIds) return false

			for (var i in this.selectedObjectIds) {
				var objectIdInfo = this.selectedObjectIds[i]
				if (objectIdInfo.meshId == -1 && objectIdInfo.nodeId == nodeId) {
					return true;
				}
			}

			return false
		},
		isNodeSubSelected(node) {
			for (var i in node.children) {
				var child = node.children[i]
				if (!child) continue;

				if (this.isNodeSelected(child.id)) {
					return true;
				}
				if (this.isNodeSubSelected(child)) {
					return true
				}
			}

			for (var meshIndex in node.meshes) {
				var meshId = node.meshes[meshIndex]
				var mesh = this.findMesh(meshId)
				if (mesh && node && this.isMeshSelected(mesh.id, node.id)) {
					return true
				}	
			}

			return false
		},
		isNodeSubHighlighted(node) {
			if (!node) return false
			if (!node.children) return false

			for (var i in node.children) {
				var child = node.children[i]
				if (this.isNodeSubHighlighted(child)) {
					return true
				}
			}

			for (var meshIndex in node.meshes) {
				var meshId = node.meshes[meshIndex]
				var mesh = this.findMesh(meshId)
				if (mesh && node && this.isMeshHighlighted(mesh.id, node.id)) {
					return true
				}	
			}

			return false
		},
		createItemForObject(object, level, type) {
			var ComponentClass = Vue.extend(ObjectItem)
			var item = new ComponentClass()

			item.$mount() // pass nothing
			item.configure(object, type)
			item.onExpandCallback = this.onExpand
			item.level = level;
			item.onSelect = this.onItemSelect;
			item.onHighlight = this.onItemHighlight

			if (object.type == "mesh") {
				item.selected = this.isMeshSelected(object.id, object.nodeId)
			} else if (object.type == "node") {
				item.selected = this.isNodeSelected(object.id)
			}
			
			return item;
		},
		onItemSelect(item) {
			this.select(false, item, false)
			this.updateSelections()
		},
		onItemHighlight(item) {
			this.select(true, item, false)
			this.updateSelections()
		},
		insertAfter(newNode, existingNode, parent) {
			if (existingNode != null && existingNode.nextSibling != null) {
				parent.insertBefore(newNode, existingNode.nextSibling);
			} else {
				parent.appendChild(newNode)
			}
		},
		addItemsForNodeObject(nodeObject, parentItem) {
			// define level
			var level = 0;
			var parentItemElement = null;
			if (parentItem) {
				parentItemElement = parentItem.$el
				level = parentItem.level + 1
			}

			// add node children
			var children = nodeObject.children;
			var itemPrev = parentItemElement
			for (var index in children) {
				var childObject = children[index]
				var newNodeItem = this.createItemForObject(childObject, level, "node")
				newNodeItem.parentItem = parentItem

				parentItem.$refs.childItems.appendChild(newNodeItem.$el)
				//this.insertAfter(newNodeItem.$el, itemPrev, this.$refs.outliner)
				this.objectItemList.push(newNodeItem)

				if (parentItem) {
					parentItem.childrenItems.push(newNodeItem)
				}

				itemPrev = newNodeItem.$el
			}

			// add meshes
			var meshes = nodeObject.meshes
			for (var meshIdIndex in meshes) {
				var meshId = meshes[meshIdIndex];

				var meshObject = this.findMesh(meshId)
				meshObject.nodeId = nodeObject.id

				var newMeshItem = this.createItemForObject(meshObject, level, "mesh")
				newMeshItem.parentObject = nodeObject
				newMeshItem.meshId = meshId
				newMeshItem.parentItem = parentItem

				parentItem.$refs.childItems.appendChild(newMeshItem.$el)
				//this.insertAfter(newMeshItem.$el, parentItemElement, this.$refs.outliner)
				this.objectItemList.push(newMeshItem)

				if (parentItem) {
					parentItem.childrenItems.push(newMeshItem)
				}
			}
		},
		removeItem(item) {
			this.removeItemChildren(item)

			if (item.$el.parentElement) {
				item.$el.parentElement.removeChild(item.$el)
			}
			const indexToDelete = this.objectItemList.indexOf(item);
			if (indexToDelete > -1) { 
				this.objectItemList.splice(indexToDelete, 1);
			}
		},
		removeItemChildren(item) {
			for (var childItemIndex in item.childrenItems) {
				var childItem = item.childrenItems[childItemIndex]
				this.removeItem(childItem);
			}
			item.childrenItems = []
		},
		onExpand(expanded, item) {
			if (expanded) {
				this.addItemsForNodeObject(item.object, item, this.$refs.outliner)
			} else {
				this.removeItemChildren(item)
			}
		},
		findMesh(meshId) {
			if (!this.scene) return null

			for (var meshIndex in this.scene.meshes) {
				var meshObject = this.scene.meshes[meshIndex]
				if (meshObject.id == meshId) {
					return meshObject
				}
			}
			return null
		},
		findNodeRecursively(nodeId, node) {
			if (!node) return null
			if (node.id == nodeId) return node;

			for (var childIndex in node.children) {
				var child = node.children[childIndex]

				var found = this.findNodeRecursively(nodeId, child)
				if (found) return found; 
			}
			return null
		},
		findNode(nodeId) {
			if (!this.scene) return null

			let found = null
			let nodeArray = this.scene.rootNodeArray
			for (let i in nodeArray) {
				let rootNode = nodeArray[i]
				found = this.findNodeRecursively(nodeId, rootNode)
				if (found) {
					break;
				}
			}
			return found
		},
		selectNodeById(isHighlight, nodeId, meshIdToSelect, fromEngine) {
			if (!isHighlight) {
				this.selectObjectRecursively(nodeId, meshIdToSelect)
			}
			
			if (nodeId == -1) {
				this.select(isHighlight, null, fromEngine)
			}
			var itemToSelect = null

			for (var itemIndex in this.objectItemList) {
				var item = this.objectItemList[itemIndex]
				if (nodeId == -1) {
					if (isHighlight) {
						item.highlight(false)
					} else {
						item.select(false)
					}
					continue;
				}

				if (item.type == "node" && item.object.id == nodeId) {
					var meshId = 0;
					for (var itemChildIndex in item.childrenItems) {
						var childItem = item.childrenItems[itemChildIndex]
						if (childItem.type == "mesh") {
							if (childItem.meshId == meshIdToSelect) {
								if (isHighlight) {
									childItem.highlight(true)
								} else {
									itemToSelect = childItem
									//select(childItem)
									//childItem.select(true)
								}
							} else {
								if (isHighlight) {
									childItem.highlight(false)
								} else {
									childItem.select(false)
								}
							}
						} else {
							if (isHighlight) {
								childItem.highlight(false)
							} else {
								childItem.select(false)
							}
						}
					}
				}
			}

			if (isHighlight) {
				var mesh = this.findMesh(meshIdToSelect)
				this.highlightedObject = mesh
				this.highlightedNodeId = nodeId
			}
			if (itemToSelect) {
				this.select(isHighlight, itemToSelect, fromEngine)
			}
			if (fromEngine) {
				this.updateSelections()
			}
		},
		deselect(fromEngine) {
			this.select(false, null, fromEngine);
		},
		selectObjectRecursively(nodeId, meshId) {
			if (meshId != -1) {
				var meshObject = this.findMesh(meshId)
				this.selectObject(meshObject, nodeId)
			} else {
				var node = this.findNode(nodeId)
					if (node) {
						for (var i in node.children) {
						var childObject = node.children[i]
						var found = this.selectObjectRecursively(childObject.id, meshId)
						if (found) return true
					}
				}
			}

			return false
		},
		selectObject(object, nodeId) {
			this.selectedObject = object;
			this.selectedNodeId = nodeId;
			if (this.onSelect) this.onSelect(object, nodeId)
		},
		select(isHighlight, itemToSelect, fromEngine) {
			var _this = this;

			this.userEditing = false;

			if (itemToSelect && !isHighlight && this.multiselect) {
				itemToSelect.select(!itemToSelect.selected)

				var selectedObjectIdInfo = {}
				if (itemToSelect.object.type == "mesh") {
					selectedObjectIdInfo.meshId = itemToSelect.object.id
					selectedObjectIdInfo.nodeId = itemToSelect.object.nodeId
				} else if (itemToSelect.type == "node") {
					selectedObjectIdInfo.meshId = -1
					selectedObjectIdInfo.nodeId = itemToSelect.object.id
				}
				if (itemToSelect.selected) {
					this.selectedObjectIds.push(selectedObjectIdInfo)
				} else {
					var index = -1;
					for (var idIndex in this.selectedObjectIds) {
						var ids = this.selectedObjectIds[idIndex]
						if (selectedObjectIdInfo.meshId == ids.meshId && selectedObjectIdInfo.nodeId == ids.nodeId) {
							index = idIndex;
							break;
						}
					}
					if (index >= 0) {
						this.selectedObjectIds.splice(index, 1)
					}
				}
			}

			// find object to select
			var newSelectedObject = null
			for (var i in this.objectItemList) {
				var item = this.objectItemList[i]
			
				if (isHighlight) {
					item.highlight(itemToSelect == item)
				} else {
					if (!this.multiselect) {
						item.select(itemToSelect == item)
					}
				}
				
				if (itemToSelect == item) {
					newSelectedObject = item.object
				}
			}
			
			var notifyEngine = true
			
			if (!isHighlight) {
				if (this.multiselect) {
					notifyEngine = false
					if (this.onSelectMultiple) this.onSelectMultiple(this.selectedObjectIds)
				} else {
					var nodeId = -1;
					if (newSelectedObject) {
						if (newSelectedObject.type == "mesh") {
							nodeId = newSelectedObject.nodeId
						} else {
							nodeId = newSelectedObject.id
						}
					}
					this.selectObject(newSelectedObject, nodeId)
					this.selectedItem = itemToSelect
				}

				this.highlightedObject = null
				this.highlightedNodeId = -1
			}
				
			
			if (!fromEngine && notifyEngine) {
				var params = {}
				params.type = isHighlight ? "highlight" : "select"
				if (newSelectedObject) {
					if (newSelectedObject.type == "node") {
						params.node = newSelectedObject.id
					} else if (newSelectedObject.type == "mesh") {
						params.node = itemToSelect.parentObject.id
						params.mesh = Number(itemToSelect.meshId)
					}
				}
				
				this.$editor.module.ccall('callFromJS', 'string', ['string', 'string'], ["selectMesh", JSON.stringify(params, null, 4)]);
			}

			this.$nextTick(() => {
				_this.userEditing = true;
			});
		}
	},
	watch: {
		
	}
}

</script>

<style lang="scss">

.object-outliner-background {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
.object-outliner {
	height: 100%;
	overflow: auto;
	position: relative;
	background-color: white;
}
</style>
