<template>
  <section class="configurator__section">
    <div class="configurator__upper in-container">
      <iframe class="configurator_promo_frame" ref="configuratorPromoFrame" src="" allowfullscreen
        mozallowfullscreen="true" webkitallowfullscreen="true" title="ARCHITEQUE 3D" frameborder="0"></iframe>

      <div class="configurator__upper-features">
        <h3 class="configurator__title-section">{{ $t("configurator.title-section") }}</h3>
        <FeaturesListLight nameOfFeatures="configurator" class="configurator__features"></FeaturesListLight>
        <b-link @click="onClickTryEditor" class="button_configurator_promo btn"
          id="application-btn-try">{{ $t('btn-try-editor-for-free') }}</b-link>
      </div>
    </div>
    <br><br>
  </section>
</template>

<script>
import FeaturesListLight from "./FeaturesListLight.vue";

export default {
  data: function () {
    return {
      isModelLoaded: false,
    }
  },
  props: {
    sceneHash: {
      default: "",
      type: String
    }
  },
  components: {
    FeaturesListLight
  },
  created() {

  },
  updated() {

  },
  mounted() {
    this.onVisible(this.$el, (visible) => {
      this.loadModel()
    })
  },
  methods: {
    loadModel() {
      if (this.isModelLoaded) return;
      this.isModelLoaded = true;

      var hash = "43d53f581c201c3f"
      if (this.sceneHash && this.sceneHash.length > 0) {
        hash = this.sceneHash
      }
      var search = window.location.search;
      if (typeof search === 'string' || search instanceof String) {
        search = search.replaceAll("?", "")
        var hashElements = search.split(",")
        var hashInURL = hashElements.length > 0 ? hashElements[0] : ""
        if (hashInURL.length > 0) {
          hash = hashInURL
        }
      }

      // Update frame src
      var src = this.getArchwebURL(true)
        + "uiStyle=dark&"
        + "allowScale=1&"
        + "allowMoveCameraSideways=1&"
        + "showCredit=0&"
        + "allow-fullscreen=0&"
        + "annotations-toggle=0&"
        + "annotations-visible=1&"
        + "show-logo=0&"
        + "show-info=0&"
        + "transparent=1&"
        + "toggle-configurator=0&"
        + "uiStyle=light&"
        + "allowScale=0&"
        + "show-default-configurator=0&"
        + "scene=" + hash;

      if (process.env.NODE_ENV === 'development') {
        src += "&api=" + process.env.VUE_APP_API_BASEURL;
        src += "&website-url" + process.env.VUE_APP_WEBSITE_URL;
        src += "&allowCDN=0";
      }
      this.$refs.configuratorPromoFrame.src = src;
    },
    onClickTryEditor() {
      if (this.$store.state.token) {
        this.$router.push({ name: 'MyScenes' });
      } else {
        this.$router.push({ name: 'Register' });
      }
    }
  },
};
</script>

<style lang="scss">
.configurator__main {
  @media (min-width: $tablet-width) {
    max-width: 800px;
  }
}

.configurator__lower_subheader {
  text-align: center;
}

#archengine-embed-container-configurator {
  width: 100%;
  margin: auto;
  aspect-ratio: 1.78;

  @media (max-width: $mobile-width-only) {
    margin-top: 30px;
  }
}

#archengine-embed-container-configurator iframe {
  background-color: #ededed;
  box-shadow: inset 0px 0px 180px rgba(0, 0, 0, 0.3);
}

.configurator__section {}

.configurator__title {
  text-align: center;
  margin-bottom: 10px !important;
  font-size: 36px !important;
  line-height: 44px !important;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $tablet-width) {
    margin-bottom: 35px !important;
  }

  @extend .font-title;

  @media (max-width: $mobile-width-only) {
    font-size: 20px !important;
    background: none !important;
  }
}

.configurator__title2 {
  text-align: center;
  margin-bottom: 10px !important;
  font-size: 28px !important;
  line-height: 44px !important;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $tablet-width) {
    margin-bottom: 35px !important;
  }

  @extend .font-title;
}

.configurator__text {
  @extend .support-text;
  max-width: 800px;
  display: inline-block;
  width: 100%;
}

.configurator__text_container {
  text-align: center;
}

.configurator__title-section {
  margin-bottom: 20px !important;

  @media (max-width: $mobile-width-only) {
    margin-top: 25px !important;
    margin-bottom: 20px !important;
    font-size: 24px !important;
  }

  @extend .title-section;

  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.15));
}

.configurator_promo_frame {
  width: 110%;
  height: 110%;

  margin-top: -80px;

  @media (max-width: $mobile-width-only) {
    width: calc(100%);
    height: 500px;

    margin-top: -70px;
  }
}

.configurator_before_img {
  width: auto;
  max-width: 100%;
  height: auto;
  padding-top: 0px;
  margin: auto;
  display: flex;
  margin-bottom: 40px;
}


.configurator__upper {
  display: grid;

  @media (min-width: $tablet-width) {
    padding: 0;
    grid-gap: 2%;
    grid-template-columns: 60% 30%;
  }

  @media (max-width: $mobile-width-only) {
    padding: 0px 0px 15px 0px !important;
    margin-top: 20px;
    width: calc(100% - 20px) !important;
  }

  border-radius: 45px;
  background-color: #59b5bb;
  color: white;
  box-shadow: inset 0px -100px 100px #00000021;
  z-index: 2;
  position: relative;
}

.configurator__lower_header {
  display: grid;

  @media (min-width: $tablet-width) {
    padding-bottom: 20px;
    grid-gap: 2%;
    grid-template-columns: 49% 49%;
  }

  @media (max-width: $mobile-width-only) {
    display: block;
    margin-bottom: 20px;
  }
}

.configurator__lower {
  display: grid;

  @media (min-width: $tablet-width) {
    padding-bottom: 70px;
    grid-gap: 2%;
    grid-template-columns: 49% 49%;
  }

  @media (max-width: $mobile-width-only) {
    display: block;
    margin-bottom: 20px;
  }
}

.configurator__upper-features {
  padding: 30px 0px;

  @media (min-width: $tablet-width) {
    padding-top: 50px;
  }

  @media (max-width: $mobile-width-only) {
    padding: 0px 30px;
  }
}

.configurator__features {
  padding-top: 0px;
  font-weight: 500;
  margin-bottom: 30px !important;
}

.descriptions__item--media::before {
  background-image: url("./../assets/media.png");
}

.descriptions__item--features::before {
  background-image: url("./../assets/features.png");
}

.descriptions__item--history::before {
  background-image: url("./../assets/history.png");
}

.descriptions__item--education::before {
  background-image: url("./../assets/education.png");
}

.configurator__subtitle {
  padding-top: 50px;
  padding-bottom: 100px;
  text-align: center;

  .configurator__title {
    display: inline;

    @extend .font-title;
  }
}

#application-btn-try {
  margin: 0 0 0 0;
  width: 100%;
}

.button_configurator_promo {
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  color: $white !important;
  width: 250px;
  background-color: #bc4444 !important;
  transition: 0.15s ease-in-out;

  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 19px 0px 20px 0px !important;
  border-radius: 40px !important;

  box-shadow: 0 16px 24px rgba(90, 181, 186, 0.2), 0 2px 6px rgba(90, 181, 186, 0.12), 0 0 1px rgba(0, 0, 0, 0.04);

  @extend .font-link-btn-1;

  margin-top: 10px !important;

  @media (min-width: $tablet-width) {
    min-width: 258px;
  }

  @media (max-width: $mobile-width-only) {
    width: 100%;
  }
}

.button_configurator_promo:hover,
.button_configurator_promo:focus {
  background-color: #bc5555 !important;
}

.button_configurator_promo:active {
  background-color: #bc5555 !important;
}</style>
