<template>
  <div class="development-bg">
    <div class="in-container development">
      <h2 class="development__title" v-html="$t('development.title')"></h2>
      <ul class="development__list development-list">
        <li class="development-list__item development-item ">
          
          <div class="development-item__text development-item__text--app">
            <h3 class="development-item__title">{{$t('development.list.first.title')}}</h3>
            <ul class="development-item__features development-item-features">
              <li class="development-item-features__item">{{$t('development.list.first.features.1')}}</li>
              <li class="development-item-features__item">{{$t('development.list.first.features.2')}}</li>
              <li class="development-item-features__item">{{$t('development.list.first.features.3')}}</li>
              <li class="development-item-features__item">{{$t('development.list.first.features.4')}}</li>
            </ul>
            <b-link v-if="isDesktop"  class="development__btn btn" :to="{name: 'MyScenes'}">{{$t('btn-try-for-free')}}</b-link>
            <b-link v-if="!isDesktop" class="development__btn btn" v-bind:href="hrefApp">{{$t('btn-download')}}</b-link>
          </div>
          <div class="development-item__img">
            <iframe src="https://www.youtube.com/embed/GRhBlHihfFM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen class="embed-video"></iframe>
            <!--img width="391px" height="442px" src="./../assets/sample02.jpg" v-bind:alt="$t('development.alt-img.phones')"-->
          </div>
        </li>
        <!--li class="development-list__item development-item">
          <div class="development-item__text development-item__text--tools">
            <h3 class="development-item__title">{{$t('development.list.second.title')}}</h3>
            <ul class="development-item__features development-item-features">
              <li class="development-item-features__item">{{$t('development.list.second.features.first')}}</li>
              <li class="development-item-features__item">{{$t('development.list.second.features.second')}}</li>
              <li class="development-item-features__item">{{$t('development.list.second.features.third')}}</li>
            </ul>
            <b-link :to="{name: 'Toolkit'}" class="development__btn btn">{{$t('btn-download')}}</b-link>
          </div>
          <div class="development-item__img development-item-2__img">
            <img width="458" height="398" src="./../assets/toolkit_preview.jpg" v-bind:alt="$t('development.alt-img.app')">
          </div>
        </li-->

        <ConfiguratorPromo></ConfiguratorPromo>

        <!--li class="development-list__item development-item">
          <div class="development-item__text development-item__text--tools">
            <h3 class="development-item__title">{{$t('development.list.web.title')}}</h3>
            <ul class="development-item__features development-item-features">
              <li class="development-item-features__item">{{$t('development.list.web.features.1')}}</li>
              <li class="development-item-features__item">{{$t('development.list.web.features.2')}}</li>
              <li class="development-item-features__item">{{$t('development.list.web.features.3')}}</li>
              <li class="development-item-features__item">{{$t('development.list.web.features.4')}}</li>
              <li class="development-item-features__item">{{$t('development.list.web.features.5')}}</li>
            </ul>
          
            <b-link :to="{name: 'Register'}" class="development__btn btn">{{$t('btn-try-for-free')}}</b-link>
          </div>
          <div id="web-integration-engine-container" class="development-item__img development-item-2__img"> 
            <iframe ref="webIntegrationExampleFrame" id="archengine-frame-2" title="ARCHITEQUE 3D" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" frameborder="0" style="margin: 0px; height: 100%;"></iframe>
          </div>
        </li-->
      </ul>
    </div>
  </div>
</template>

<script>

import ConfiguratorPromo from './ConfiguratorPromo.vue';

export default {
  data: function() {
    return {
      isDesktop: false
    }
  },
  components: {
    ConfiguratorPromo
  },
  computed: {
    hrefApp: function() {
      let os = "";
  
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        os = "Android";
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        os = "iOS";
      } else {
        os = "unknown";
      }
      
      if (os === "Android") {
        return "https://play.google.com/store/apps/details?id=com.architeque.android.app"; 
      } else {
        return "https://itunes.apple.com/app/apple-store/id1447699048";
      }
    }
  },
  mounted() {
    var archengineFrame = document.getElementById('archengine-frame-2');
    var search = window.location.search;
    if (typeof search === 'string' || search instanceof String) {
      search = search.replaceAll("?", "")
      var hashElements = search.split(",")
      var hash = hashElements.length > 1 ? hashElements[1] : ""
      if (hash.length > 0) {
        archengineFrame.src = archengineFrame.src + "&scene=" + hash
      }
    }

    this.isDesktop = this.getMobileOperatingSystem() == "unknown";
  }
}
</script>
<style lang="scss">

.development {
  padding-top: 70px;
  padding-bottom: 75px;

  @media (max-width: $mobile-width-only) {
    padding-top: 30px;
  }
}

#web-integration-engine-container {
  width: 100%;
  height: 100%;
  min-height: 300px;
  
  padding: 20px;

  @media (max-width: $mobile-width-only) {
    width: 90%;    
    padding: 0px !important;
  }
}

#web-integration-engine-container iframe {
  background-color: #ededed;
  box-shadow: inset 0px 0px 80px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

.development-item {
  display: grid;

  @media (min-width: $tablet-width) {
    grid-gap: 7%;
    grid-template-columns: 47% 47%;
  }
}

.development-item:not(:last-child) {
  margin-bottom: 80px;
  @media (min-width: $tablet-width) {
    margin-bottom: 75px;
  }
}

.development-item__img {
  display: flex;
  justify-content: center;
  align-items: center;
  order: 0;
  margin-right: auto;
  margin-left: auto;

  border-radius: 30px;
  width: 100%;

  @media (min-width: $tablet-width) {
    align-self: center;  
    min-width: 250px;
    height: 100%;
    margin: 0;
  }
}

.development-item__img img {
  width: auto;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4), 0 2px 6px rgba(0, 0, 0, 0.4), 0 0 1px rgba(0, 0, 0, 0.4);
}

.development-item-2__img img {
  box-shadow: none;
}

.development-item__text {
  position: relative;

  padding-top: 42px;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: $tablet-width) {
    padding-top: 22px;
    padding-right: 5%;
    padding-left: 2%;
  }

  @media (max-width: $mobile-width-only) {
    padding-bottom: 30px;
  }
}

.development-list,
.development-item-features {
  padding: 0 0 40 0 !important;

  font-size: 18px;
  line-height: 22px;
  color: $secondary-dark;

  list-style: none;
  @extend .font-main;

  @media (max-width: $mobile-width-only) {
    margin-bottom: 20px !important;
  }
}

.development-item-features__item {
  position: relative;

  padding-left: 34px;
}

.development-item-features__item:not(:last-child) {
  margin-bottom: 25px;
}

.development-item-features__item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;

  display: block;
  width: 22px;
  height: 22px;

  background-image: url("./../assets/icon-checked.svg");
}

.development-bg {
  background-color: $bg-block2;
}

.development__title {
  @extend .title-chapter;
}

.development-item__title {
  margin-bottom: 20px !important;

  @extend .title-section;

  @media (max-width: $mobile-width-only) {
    font-size: 22px !important;
  }
}


.development-item__text--app {
  @media (min-width: $tablet-width) {
    order: 1;
  }
}

.development-item__text--tools {
  order: 1;
  @media (min-width: $tablet-width) {
    order: -1;
  }
}

.development__btn {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  padding: 19px 74px 20px 56px !important;
  font-weight: 600 !important;

  box-shadow: 0 16px 24px rgba(90, 181, 186, 0.2), 0 2px 6px rgba(90, 181, 186, 0.12), 0 0 1px rgba(0, 0, 0, 0.04);

  @extend .font-link-btn-1;

  @extend .button;
  
  @media (min-width: $tablet-width) {
    min-width: 258px;
  }
}


</style>